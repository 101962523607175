<template>
	<div class="grid-box m-l-minus-16">
		<v-layout row wrap wrap-dash>
			<div class="ficha">
				<div class="gradient-banner"></div>
				<div class="p-relative">
					<div class="row">
						<div class="logo-instituicao">
							<img src="../../../assets/images/logo-large.png" />
						</div>
					</div>
				</div>
				<v-layout row>
					<div class="col-md-6 mr-5 content-banner">
						<div class="banner-subtitle">Relatório - Visão Geral</div>
						<h1>Campanhas</h1>
						<a href="index.html" class="voltar">
							<i class="fal fa-long-arrow-left"></i> voltar à página do
							projeto
						</a>
					</div>
				</v-layout>
				<v-menu offset-y>
					<template v-slot:activator="{ on }">
						<button v-on="on" class="menu-overview flex-inherit px-5 v-btn--flat v-btn--large v-btn--round">
                            <Icon name="fal fa-bars" size="16" class="mr-2" colorFont="#02ACB4"/>
                            Menu
                        </button>
					</template>
					<v-list class="options-menu-global">
						<v-list-tile v-for="(item, index) in MenuNavegation" :key="index" @click>
							<v-list-tile-title>{{ item.title }}</v-list-tile-title>
						</v-list-tile>
					</v-list>
				</v-menu>
			</div>
		</v-layout>
        <v-layout bg-white pa-4 ma-4 mt-5 column br-4>
			<div class="my-donations-header column display-flex fill-height">
				<div>
					<Icon name="fal fa-tasks" size="30" class="mr-3" colorFont="#C3C6D5"/>
					<h2>MinhasTarefas</h2>
				</div>
				<div>
					<InputSearch type="text" placeHolder="Buscar..." />
				</div>
			</div>
			<BorderSeparation class="no-margin"/>
			<div class="fill-height justify-center align-center display-flex m-height-600">
				<div class="box-done-task">
					<img src="../../../assets/images/EmptyTasks@3x.png" alt="">
					<H2>Parabéns, você completou todas as tarefas.</H2>
					<p>Uma dica, vá para a página de <a href="#"> Visão Geral</a> e acompanhe, em <br /> tempo real, como estão indo suas campanhas.</p>
					<a href="#" class="btn-primary v-btn--largee ma-0 v-btn--large v-btn--round white--text v-btn">Visão Geral</a>
				</div>
			</div>
        </v-layout>
		<!-- <v-layout pa-4 ma-4 column br-4>
			<div class="fill-height p-relative justify-center align-center display-flex m-height-600">
				<div class="info-menu">
					Acesse aqui o menu da sua empresa.
					<img class="ml-3" src="../../../assets/images/seta.svg" alt="">
				</div>
				<div class="box-done-task">
					<img src="../../../assets/images/NoTasks@3x.png" alt="">
					<H2>Estamos criando um dashboard incrível <br /> para você acompanhar suas campanhas.</H2>
				</div>
			</div>
        </v-layout> -->
		<h2 class="title-section-alone ma-4">Resumo das Campanhas</h2>
		<v-layout bg-white pa-4 ma-4 column br-4>
			<div class="title-section-box">
				<div>
					<h5>Votorantim Cimentos</h5>
					<h3>Anjo da Guarda</h3>
				</div>
				<div>
					<button class="btn-line">
						<Icon name="fal fa-minus-circle" size="16" class="mr-3" colorFont="#C3C6D5"/>
						Resumo
					</button>
				</div>
			</div>
			<div class="values-boxes mt-4">
				<div>
					<span>Valor captado</span>
					<div>R$ 1.998.250</div>
				</div>
				<div>
					<span>Doadores</span>
					<div>15.986 U / 3.409 R</div>
				</div>
				<div>
					<span>Média de doação</span>
					<div>R$125</div>
				</div>
				<div>
					<span>Projetos</span>
					<div>3</div>
				</div>
				<div>
					<span>Empresas</span>
					<div>5</div>
				</div>
			</div>
		</v-layout>
	</div>
</template>
<script type="plain/text">
export default {
	data: () => ({
		MenuNavegation: [
			{
                title: "Dashboard Votorantim",
            },
			{ title: "Perfil" },
			{ title: "Equipes e Usuários" },
			{ title: "Gestão de Campanhas" },
			{ title: "Visão Geral" },
			{ title: "Meus Projetos" },
			{ title: "Relatório Analítico" },
			{ title: "Fluxo de Recursos" }
		]
	})
};
</script>

<style>
</style> 